import { BoxV2 as Box } from 'portal-commons';
import React, { useState, useEffect } from 'react';
import { faAngleLeft } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container, Grid } from '@material-ui/core';

import {
  fetchProfile,
  getEventHistory,
  getSharingChain,
  getCampaignBrandMNOSuspensionApi,
  getMNOMetaDataApi,
  getCampaignRoles,
  getMigrationCNP,
} from '../apis';
import {
  CampaignBasicDetails,
  CampaignBrandDetails,
  CampaignCspDetails,
  CampaignAttributes,
  CampaignDescription,
  CampaignSampleMessages,
  CampaignCarrierStatus,
  CampaignSampleMultimedia,
  NumbersProvisioned,
  CTAMultiMediaList,
} from '../components';
import CampaignEventHistory from '../components/CampaignEventHistory';
import ElectCNPAlert from '../components/ElectCNPAlert';
import SharingChain from '../components/SharingChain';
import { CSP_CAMPAIGNS_DEFAULT_ENTRY } from '../../../constants/paths';
import { useAuthContext } from '../../../contexts';
import { Loader } from '../../../shared_elements';
import { sortMnoList } from '../../../utils';
import { globalGetService } from '../../../utils/globalApiServices';

import '../../../assets/styles/campaign-detail-module.scss';

export default function CampaignDetail(props) {
  const { role } = useAuthContext();
  const [loader, setLoader] = useState(true);
  const [profile, setProfile] = useState({});
  const [campaignDetail, setCampaignDetail] = useState({});
  const [sharingNodes, setSharingNodes] = useState([]);
  const [eventInfo, setEventInfo] = useState({});
  const [eventsLoading, setEventsLoading] = useState(false);
  const [electAlertOpen, setElectAlertOpen] = useState(false);
  const [brandMNOSuspensionDetail, setBrandMNOSuspensionDetail] = useState([]);
  const [migrationInfo, setMigrationInfo] = useState();
  const [provisional, setProvisional] = useState();
  const [isOnExistingChain, setIsOnExistingChain] = useState();
  const campaignUid = props.match.params.id;

  useEffect(() => {
    updateProfile();
  }, []);

  useEffect(() => {
    updateProvisionalFlag(campaignUid);
    fetchEventHistory(campaignUid);
    return () => {
      setProvisional(undefined);
      setCampaignDetail({});
    };
  }, [campaignUid]);

  useEffect(() => {
    if (campaignUid && provisional != undefined) {
      updateProvisionalFlagDependentData(campaignUid, provisional);
    }
  }, [campaignUid, provisional]);

  const updateProvisionalFlag = async (campaignUid) => {
    let provisional = false;
    const migrationInfo = await getMigrationCNP(campaignUid, null, {
      silent: true,
    });
    setMigrationInfo(migrationInfo);

    try {
      const roles = await getCampaignRoles(campaignUid);
      const isOnChain = (role) => role && !['NONE', 'UNSET'].includes(role);

      setIsOnExistingChain(isOnChain(roles.normalRole));
      provisional =
        migrationInfo?.status === 'ACTIVE' && isOnChain(roles?.provisionalRole);
    } catch {
      setIsOnExistingChain(true);
    }
    setProvisional(provisional);
  };

  const updateProvisionalFlagDependentData = (campaignUid, provisional) => {
    fetchCampaignDetail(campaignUid, provisional);
    updateSharingChain(campaignUid, provisional);
  };

  const fetchCampaignDetail = async (campaignUid, provisional) => {
    globalGetService(`dca/campaigns/${campaignUid}`, { provisional }).then(
      (response) => {
        if (response.status >= 200 && response.status < 300) {
          setCampaignDetail(response.data);
          setLoader(false);
        }
      }
    );
  };

  function splitArrayIntoChunks(arr, chunkSize) {
    if (!Array.isArray(arr) || !Number.isInteger(chunkSize) || chunkSize <= 0) {
      throw new Error(
        'Invalid input. The first argument should be an array and the second argument should be a positive integer.'
      );
    }

    const result = [];
    for (let i = 0; i < chunkSize; i++) {
      const chunk = [];
      for (let j = i; j < arr.length; j += chunkSize) {
        chunk.push(arr[j]);
      }
      result.push(chunk);
    }
    return result.reduce((flattened, chunk) => flattened.concat(chunk), []);
  }

  const fetchBrandMNOSuspensionDetail = async (brandUid) => {
    const response = await getCampaignBrandMNOSuspensionApi(brandUid);
    const mnoMetaData = await getMNOMetaDataApi();
    let mappedResponse = response.map((suspendedMNO) => {
      let result = { ...suspendedMNO };
      mnoMetaData.forEach((mnoMetaData) => {
        if (suspendedMNO.mnoId === mnoMetaData.networkId) {
          result.displayName = mnoMetaData.displayName;
          result.osrBitmaskIndex = mnoMetaData.osrBitmaskIndex;
        }
      });
      return result;
    });
    // Sorting
    mappedResponse = splitArrayIntoChunks(
      sortMnoList(mappedResponse, ['displayName']),
      2
    );
    setBrandMNOSuspensionDetail(mappedResponse);
  };

  const fetchEventHistory = async (campaignUid, page = 1) => {
    setEventsLoading(true);
    const response = await getEventHistory({
      campaignUid,
      page,
    });
    setEventInfo(response);
    setEventsLoading(false);
  };

  const updateSharingChain = async (campaignUid, provisional) => {
    const sharingChain = await getSharingChain(campaignUid, { provisional });
    setSharingNodes(sharingChain?.nodes ?? []);
  };

  const updateProfile = async () => {
    const profile = await fetchProfile();
    setProfile(profile);
  };

  useEffect(async () => {
    if (campaignDetail.brandUid) {
      await fetchBrandMNOSuspensionDetail(campaignDetail.brandUid);
    }
  }, [campaignDetail]);

  const handleGoBack = () => {
    if (props.location.state && props.location.state.goBackPage) {
      props.history.push(props.location.state.goBackPage);
    } else {
      props.history.push(CSP_CAMPAIGNS_DEFAULT_ENTRY);
    }
  };

  const handlePageChange = (page) => {
    fetchEventHistory(props.match.params.id, page);
  };

  const handleDcaMenuClick = () => {
    if (campaignDetail && campaignDetail.usecase === 'SOLE_PROPRIETOR') {
      setElectAlertOpen(true);
    }
  };

  const handleAcceptElection = () => {
    setProvisional(undefined);
    updateProvisionalFlag(campaignUid);
  };

  return (
    <section className="campaign-detail-section" data-testid="campaignDetail">
      <Container maxWidth={false} className="campaign-detail-container">
        {loader ? (
          <Loader />
        ) : (
          <Grid container>
            <Grid item xs={12}>
              <div className="top-block">
                <p className="back-btn">
                  <a
                    data-testid="campaignDetailBackButton"
                    onClick={handleGoBack}
                  >
                    <Box sx={{ display: 'inline-flex', flexDirection: 'row' }}>
                      <Box mr="xs">
                        <FontAwesomeIcon
                          icon={faAngleLeft}
                          style={{ color: 'C4C8C9' }}
                        />
                      </Box>
                      <span>
                        {props.location.state
                          ? ['/dashboard'].includes(
                              props.location.state.goBackPage
                            )
                            ? 'back to dashboard'
                            : 'back to campaigns'
                          : 'back'}
                      </span>
                    </Box>
                  </a>
                </p>
                <CampaignBasicDetails
                  campaign={campaignDetail}
                  migrationInfo={migrationInfo}
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <CampaignCarrierStatus
                profile={profile}
                campaign={campaignDetail}
                onAcceptElection={handleAcceptElection}
                role={role}
                provisional={provisional}
                existingChain={isOnExistingChain}
                goBackPage={
                  props.location.state && props.location.state.goBackPage
                }
                onDcaMenuClick={handleDcaMenuClick}
              />
            </Grid>
            <Grid item xs={12}>
              <SharingChain
                nodes={
                  campaignDetail.isPrimaryDca ||
                  campaignDetail.secondarySharingStatus === 'ACCEPTED'
                    ? sharingNodes
                    : sharingNodes.slice(0, -1)
                }
              />
            </Grid>
            <Grid item xs={12}>
              <NumbersProvisioned campaignUid={campaignDetail.uid} />
            </Grid>
            <Grid item xs={12}>
              <CampaignBrandDetails
                profile={profile}
                campaign={campaignDetail}
                brandMNOSuspension={brandMNOSuspensionDetail}
              />
            </Grid>
            <Grid item xs={12}>
              <CampaignCspDetails campaign={campaignDetail} />
            </Grid>
            <Grid item xs={12}>
              <CampaignAttributes campaign={campaignDetail} />
            </Grid>
            <Grid item xs={12}>
              <CampaignDescription campaign={campaignDetail} />
            </Grid>
            <Grid item xs={12}>
              <CTAMultiMediaList campaignUid={campaignUid} />
            </Grid>
            <Grid item xs={12}>
              <CampaignSampleMessages campaign={campaignDetail} />
            </Grid>
            <Grid item xs={12}>
              <CampaignSampleMultimedia campaignUid={campaignUid} />
            </Grid>
            <Grid item xs={12}>
              <CampaignEventHistory
                loading={eventsLoading}
                eventInfo={eventInfo}
                onPageChange={handlePageChange}
              />
            </Grid>
          </Grid>
        )}
      </Container>
      <ElectCNPAlert
        open={electAlertOpen}
        onClose={() => setElectAlertOpen(false)}
      />
    </section>
  );
}
